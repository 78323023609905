//------------------------------------------------------------------------------
// COLUMNS

@mixin make-columns() {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint);

    @include media-breakpoint-up($breakpoint) {
      @for $i from 1 through $columns {
        .column#{$infix}-#{$i} {
          column-count: $i;
          column-gap: rem(40);
        }
      }
    }
  }
}

@include make-columns();
