//------------------------------------------------------------------------------
// TRANSITIONS

.fade {
  transition: opacity 150ms linear;

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 350ms ease;
}
